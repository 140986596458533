import { createRouter, createWebHistory, Router } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import ProjectView from '../views/ProjectView.vue';
import ContactView from '../views/ContactView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView,
  },
  {
    path: '/project',
    name: 'project',
    component: ProjectView,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: HomeView,
  },
];

const router = createRouter({
  mode: 'history', // 開啟 history 模式需要服務端支持
  base: '/', // 配置 nginx 訪問結構
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory('/AraizenTest1/'),
  routes,
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
export default router;
