<template>
    <div class="project ContactCon">
      <!-- <script src="https://unpkg.com/sweetalert/dist/sweetalert.min.js"></script> -->
        <div class="tittle">
            <p class="text1" id="ContactUs">{{ $t('ff.ContactTab') }}</p>
        </div>
          <div>
          <div>
            <form ref="form" @submit.prevent="sendEmail" class="custom-form">
              <div class="Text">
                <div class="form-container">
                  <div class="form-group align-left">

                    <label class="name" for="name">{{ $t('ff.name') }}
                      <span class="red-star">*</span><br>
                      <input type="text" id="name" name="from_name" class="inputCont"
                         :placeholder="$t('ff.nameP')" required> <!--value="form.name"-->
                    </label>

                    <label class="address" for="address">
                      {{ $t('ff.address') }}
                      <span class="red-star">*</span><br>
                        <select id="address" class="inputCont" name="from_area" required>
                          <option value="none" selected disabled hidden>
                                    {{$t('ff.addressP')}}</option>
                          <option>{{ $t('ff.address1') }}</option>
                          <option>{{ $t('ff.address2') }}</option>
                          <option>{{ $t('ff.address3') }}</option>
                          <option>{{ $t('ff.address4') }}</option>
                          <option>{{ $t('ff.address5') }}</option>
                        </select>
                    </label>

                    <label class="number" for="number">{{ $t('ff.number') }}
                      <span class="red-star">*</span><br>
                      <div class="LRNum">
                        <div class="leftNum">
                          <input type="text"  name="from_call1" class="inputCont" id="call1"
                                  :placeholder="$t('ff.numberC')" required>
                        </div>

                        <div class="rightNum">
                          <input type="text"  name="from_call2" class="inputCont" id="call2"
                                :placeholder="$t('ff.numberP')" required>
                        </div>
                      </div>

                    </label>

                    <label class="email" for="email">{{ $t('ff.mail') }}
                        <span class="red-star">*</span><br>
                        <input type="email" id="email"  name="from_email" class="inputCont"
                            :placeholder="$t('ff.mailP')" required>
                    </label>
                  </div>

                  <div class="form-group align-right">
                    <label class="company" for="company">
                      {{ $t('ff.company') }}
                      <span class="red-star">*</span><br>
                      <input type="text" id="company"  name="Com" class="inputCont"
                        :placeholder="$t('ff.companyP')" required>
                    </label>

                    <div class="CheckBox">
                      <div class="rightBoxDemand">
                        {{ $t('ff.CheckBox') }}
                        <span class="red-star">*</span>
                      </div>

                      <div class="checkbox-group">
                        <label class="CheckBox01" for="vehicle1">
                          <input type="checkbox" id="vehicle1"
                                name="CheckBox01">
                          <span>{{ $t('ff.CheckBox01') }}</span>
                        </label>
                        <label class="CheckBox02" for="vehicle2">
                          <input type="checkbox" id="vehicle2"
                                name="CheckBox02">
                          <span>{{ $t('ff.CheckBox02') }}</span>
                        </label>
                        <label class="CheckBox03" for="vehicle3">
                          <input type="checkbox" id="vehicle3"
                                name="CheckBox03">
                          <span>{{ $t('ff.CheckBox03') }}</span>
                        </label>
                        <label class="CheckBox04" for="vehicle4">
                          <input type="checkbox" id="vehicle4"
                                name="CheckBox04">
                          <span>{{ $t('ff.CheckBox04') }}</span>
                        </label>
                        <label class="CheckBox05" for="vehicle5">
                          <input type="checkbox" id="vehicle5"
                                name="CheckBox05" >
                          <span>{{ $t('ff.CheckBox05') }}</span>
                        </label>
                        <label class="CheckBox06" for="vehicle6">
                          <input type="checkbox" id="vehicle6"
                                name="CheckBox06">
                          <span>{{ $t('ff.CheckBox06') }}</span>
                        </label>
                        <div class="ContText">
                            <textarea type="text" id="reqText" name="from_ContText"
                            :placeholder="$t('ff.reqP')" required></textarea>

                        </div>

                      </div>
                    </div>

                    <div class="Slider">
                        <div class="tittle">{{ $t('ff.Slider') }}
                          <span class="red-star"> *</span><br>
                        <!-- <div class="Price">
                          <p class="NT">NT$</p>
                          <h1 content="telephone=no" class="SliderNum"
                              id="money" :key="num" :value="num"
                              name="money1">
                            {{ currentStep }}</h1>
                        </div>
                      </div>

                      <div class="mainSlider">
                        <div class="slider-container">
                          <input
                            type="range"
                            class="custom-slider"
                            list="markers"
                            name="money"
                            :min="minValue"
                            :max="maxValue"
                            :step="step"
                            v-model="sliderValue">
                            <div class="TickMark">
                              <div class="tick-marks1"/>
                              <div class="tick-marks2"/>
                              <div class="tick-marks3"/>
                              <div class="tick-marks4"/>
                              <div class="tick-marks5"/>
                              <div class="tick-marks6"/>
                              <div class="tick-marks7"/>
                              <div class="tick-marks8"/>
                              <div class="tick-marks9"/>
                              <div class="tick-marks10"/>
                              <div class="tick-marks11"/>
                              <div class="tick-marks12"/>
                            </div>
                        </div>
                      </div>
                      <div class="range range-x x" b-class="range.rootclass">
                        <div class="minmaxValue">
                          <p class="p1">50K</p>
                          <p class="p2">1,000K</p>
                        </div> -->
                      </div>
                              <div class="price-input">
                                <div class="field">
                                  <span>Min</span>
                                  <input type="number" class="input-min" name="input-min" disabled
                                            :placeholder="numK[rangeMin]" v-model="numK[rangeMin]">
                                </div>
                                <div class="separator">-</div>
                                <div class="field">
                                  <span>Max</span>
                                  <input type="number" class="input-max" name="input-max" disabled
                                            :placeholder="numK[rangeMax]" v-model="numK[rangeMax]">
                                </div>
                              </div>
                              <div class="silder">
                                <div class="progress" :style="ProgressStyle"></div>
                              </div>
                              <div class="range-input">
                                <input type="range" class="range-min" min="0" max="11"
                                              name="rangeMin" step="1" v-model="rangeMin">
                                <input type="range" class="range-max" min="0" max="11"
                                              name="rangeMax" step="1" v-model="rangeMax">
                          <div class="slider-container">
                                <!-- <div class="TickMark">
                                  <div class="tick-marks1"/>
                                  <div class="tick-marks2"/>
                                  <div class="tick-marks3"/>
                                  <div class="tick-marks4"/>
                                  <div class="tick-marks5"/>
                                  <div class="tick-marks6"/>
                                  <div class="tick-marks7"/>
                                  <div class="tick-marks8"/>
                                  <div class="tick-marks9"/>
                                  <div class="tick-marks10"/>
                                  <div class="tick-marks11"/>
                                  <div class="tick-marks12"/>
                                </div> -->
                              </div>
                            </div>
                              <div class="range range-x x" b-class="range.rootclass">
                                <div class="minmaxValue">
                                  <p class="p1">50K</p>
                                  <p class="p2">1,000K</p>
                                </div>
                              </div>
                  </div>
                  <button type="submit" id="Send" value="Send" onClick="fbq('track', 'Lead');">
                    {{ $t('ff.Send') }}</button>
                </div>
              </div>
              </div>
            </form>
          </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import emailjs from 'emailjs-com';

export default {
  data() {
    return {
      sliderValue: 0,
      minValue: 0,
      maxValue: 11,
      value: 0,
      step: 1,
      rangeMin: 1,
      rangeMax: 10,
      steps: [10000, 20000, 20000, 50000, 50000, 50000, 50000, 100000, 100000,
        250000, 250000, 250000, 500000],
      nums: [50000, 60000, 80000, 100000, 150000, 200000, 250000,
        300000, 400000, 500000, 750000, 1000000],
      numK: ['50,000', '60,000', '80,000', '100,000', '150,000', '200,000', '250,000',
        '300,000', '400,000', '500,000', '750,000', '1,000,000+'],
    };
  },
  watch: {
    rangeMax() {
      if (this.rangeMax - this.rangeMin < 2) {
         this.rangeMax = parseInt(this.rangeMin, 10) + 1;
      }
    },
    rangeMin() {
      if (this.rangeMax - this.rangeMin < 2) {
        this.rangeMin = parseInt(this.rangeMax, 10) - 1;
      }
    },
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/emailjs-com@3/dist/email.min.js';
    script.src = 'https://www.npmjs.com/package/emailjs-com';
    fetch('json/projectTab.json')
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Failed to fetch data.json');
      })
      .then((data) => {
        this.classifications = data;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  },
  computed: {
    currentStep() {
      const currentValue = parseInt(this.sliderValue, 10);
      if (this.sliderValue >= this.maxValue) {
        return `${this.nums[currentValue].toLocaleString()}+ `;
      }
      return `${this.nums[currentValue].toLocaleString()} `;
    },
    ProgressStyle() {
      return {
          left: `${this.rangeMin * 9}%`,
          right: `${100 - this.rangeMax * 9}%`,
        };
    },
  },
  methods: {
    sendEmail(event) {
      event.preventDefault();
      const serviceID = 'service_80y9jfr';
      const templateID = 'template_1wffzgj';
      const userID = 'lZTXKMbTQRZ3qXnNp';
      // const templateParams = {
      //   name: document.getElementById('name').value,
      //   address: document.getElementById('address').value,
      //   call1: document.getElementById('call1').value,
      //   call2: document.getElementById('call2').value,
      //   gmail: document.getElementById('email').value,
      //   company: document.getElementById('company').value,
      //   CheckBox01: document.getElementById('vehicle1').value,
      //   CheckBox02: document.getElementById('vehicle2').value,
      //   CheckBox03: document.getElementById('vehicle3').value,
      //   CheckBox04: document.getElementById('vehicle4').value,
      //   CheckBox05: document.getElementById('vehicle5').value,
      //   CheckBox06: document.getElementById('vehicle6').value,
      //   ContText: document.getElementById('reqText').value,
      //   money: document.getElementById('money').value,
      // };
      const formSele = '.custom-form';
      emailjs.sendForm(serviceID, templateID, formSele, 'lZTXKMbTQRZ3qXnNp')
      /* this.$refs.form  */
        .then(() => {
          console.log(this.numK[this.rangeMin], '～', this.numK[this.rangeMax]);
          console.log('Sent');
          alert('Sent');
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
          alert(JSON.stringify(err));
        });
    },
  },
};
</script>

<style>
#height_slider .e-handle,
#gradient_slider .e-handle {
  height: 20px;
  width: 20px;
  margin-left: -10px;
  top: calc(50% - 10px);
}
  .ContactCon{
    position: relative;
    padding: 0 0 0 0;
  }

  .ContactCon .text1{
    font-size: 2vw;
    font-weight: bold;
    margin-left: 12vw;
    padding: 1vw 6vw 2vw 5vw;
    text-align: left;
  }

  .ContactCon .form {
    font-family: 'Courier New', Courier, monospace;
  }

  .ContactCon .custom-form {
    max-width: 100%;
    padding: 0% 0% 5%;
    text-align: left;
    flex-wrap: wrap;
  }

  .ContactCon .Text {
    display: flex;
    justify-content: space-between;
  }

  .ContactCon .Text .form-container{
    display: flex;
    width: 100%;
  }

  .ContactCon .align-left {
    padding: 0% 0% 0% 16%;
    margin-bottom: 5vw;
    width: 35%;
    z-index: 1;
  }

  .ContactCon .align-right {
    padding: 0 0% 0% 15%;
    display: flex;
    flex-direction: column;
  }

  .red-star {
    color: red;
  }

  .ContactCon .Text label {
    display: block;
    font-size: 1.15vw;
    padding: 1vw;
    color: #85CEC1;
  }

  .ContactCon .Text .inputCont {
    width: 30vw;
    height: 3vw;
    font-size: 1vw;
    padding: 0.5vw 0vw 0.5vw 0.5vw;
    margin: 1vw 0vw 0vw 0vw;
    color: #b2a2ff;
    background-color: #111110;
    border: 0.1vw solid #5A47B8;
    border-radius: 0.5vw;
  }

  .ContactCon .align-left .number .LRNum {
    display: flex;
    align-items: center;
  }

  .ContactCon .align-left .number .LRNum .leftNum .inputCont{
    width: 10vw;
    margin-right: 1vw;
  }

  .ContactCon .align-left .number .LRNum .rightNum .inputCont{
    width: 19vw;
    margin-right: 1vw;
  }

  .ContactCon button {
    position: relative;
    top: 5vw; right: 8vw;
    height: 3vw; width: 13vw;
    padding: 0.8vw 5vw;
    font-size: 1vw; font-weight: bold;
    color: #000000;
    background-color: #85CEC1;
    border: 0.1vw solid #85CEC1;
    border-radius: 0.35vw;
    cursor: pointer;
  }

  .ContactCon button:hover {
    font-weight:bold;
    box-shadow: 0 0 10px #85CEC1;
  }

  .ContactCon #address {
    cursor: pointer;
    -webkit-appearance: none;
    width: 30vw;
    height: 3vw;
    background: url('../assets/data/BackGround/arrow.png')210px center no-repeat #111110;
    background-size: 20px auto;
    background-position: calc(100% - 1vw) center;
    border: 1px solid #5A47B8;
    color: #5A47B8;
    border-radius: 0.5vw;
  }

  .ContactCon #address option {
      max-height: 100px;
      overflow: hidden;
      white-space: nowrap;
  }

  .ContactCon .align-right .CheckBox {
    color: #8871fc;
    width: 37vw;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }

  .ContactCon .align-right .CheckBox .rightBoxDemand{
    display: block;
    font-size: 1.15vw;
    width: 46vw;
    padding: 1vw;
    font-weight: bold;
    color: #85CEC1;
    z-index: 1;
  }

  .ContactCon .align-right .CheckBox .checkbox-group{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    z-index: 5;
  }

  .ContactCon .align-right .CheckBox .checkbox-group span:hover,
   #vehicle1:hover, #vehicle2:hover, #vehicle3:hover, #vehicle4:hover, #vehicle5:hover,
   #vehicle6:hover{
    cursor: pointer;
  }

  .ContactCon .align-right .CheckBox .checkbox-group span{
    position: relative;
    font-size: 1vw;
    left: 1vw;
    bottom: 1vw;
  }
  .ContactCon .align-right .CheckBox .checkbox-group label{
    height: 30px;
    color: #b2a2ff;
    z-index: 100;
  }

  .ContactCon .align-right .CheckBox .ContText{
    position: relative;
    grid-column: span 3;
    height: 5.25vw; width: 30.5vw;
    color: #5A47B8;
    border-radius: 0.5vw;
    top: 1.1vw; left: 0.5vw;
  }

  .ContactCon .Text input{
    border: none; outline: none;
    padding: 0.5vw 0vw 2vw 0.5vw;
    width: 100%; height: 100%;
    background-color: #111110;
    border: 0.1vw solid #5A47B8;
    border-radius: 0.5vw;
    text-align: left;
    word-wrap: break-word;
    left: 0.5vw;
  }

  .ContactCon .CheckBox .ContText input {
    border: 1px solid #5A47B8;
    color:#5A47B8;
    font-size: 1vw;
  }

  .ContactCon .Text input:valid, .ContactCon #address:valid,
   .ContactCon #email:valid .ContactCon .Text .inputCont:valid{
    color: #b2a2ff;
  }

  #vehicle1,#vehicle2,#vehicle3, #vehicle4,#vehicle5,#vehicle6{
    width: 1vw;
    height: 1vw;
    position: relative;
    top: -1vw;
  }
.slider label {
  font-size: 24px;
  font-weight: 400;
  font-family: Open Sans;
  padding-left: 10px;
  color: black;
}
.slider input[type="range"] {
  width: 420px;
  height: 2px;
  background: black;
  border: none;
  outline: none;
}

  .Slider .tittle {
    font-size: 1.15vw;
    padding: 3vw 0vw 0vw 1vw;
    font-weight: bold;
    color: #85CEC1;
    z-index: 1;
  }

  .Slider .Price .NT{
    font-size: 1vw;
    font-weight: bold;
    color: #5A47B8;
    margin: 2vw 0vw 0vw 15vw;
  }

  .Slider .p1,.Slider .p2{
    position: relative;
    color: #BDB6E2;
    font-size: 1vw;
    top: 3vw;
    left: 0vw;
  }

  .Slider .p2{
    left: 28vw;
  }
  .Slider .SliderNum{
    position: relative;
    font-size: 1.5vw;
    color: #BDB6E2;
    left: 18vw;
    top: -1.5vw;
    border-bottom:2px #7B6CC6 solid;
    padding-bottom:2px;
  }
  .ContactCon .Text .custom-slider{
    background-color: #605790;
    height: 1.1px; width: 30vw;
    border: none;
    border-radius: 5px;
    padding: 0%;
    outline: none;
    appearance: none;
  }
  .ContactCon .Text #setting{
    display: flex;
    justify-content: space-between;
    height: auto;
    overflow: hidden;
    margin-top: 16px;
  }
  .ContactCon .Price {
      position: relative;
      top: -2.5vw;
      left: 5vw;
      width: 7vw;
  }
  .ContactCon .align-right .Slider .minmaxValue {
    font-size: 1.1vw;
    color: #b2a2ff;
    display: flex;
    align-items: center;
    padding: 0 0 0 0vw;
    z-index: 100;
  }
  .slider-container .tick-marks1,.slider-container .tick-marks2,
  .slider-container .tick-marks3, .slider-container .tick-marks4,
  .slider-container .tick-marks5, .slider-container .tick-marks6,
  .slider-container .tick-marks7, .slider-container .tick-marks8,
  .slider-container .tick-marks9, .slider-container .tick-marks10,
  .slider-container .tick-marks11, .slider-container .tick-marks12{
    position: relative;
    height: 9px;
    width: 2.71vw;
    border-left: solid 3px #605790;
    padding: 0;
    box-sizing: border-box;
    display: table-cell;
    top: -9px;
    z-index: -1;
  }

  .Slider .slider-container input::-webkit-slider-thumb {
    appearance: none;
    width: 1.6vw;
    height: 1.6vw;
    background-color: #b2a2ff;
    border-radius: 50%;
    cursor: pointer;
  }
  .mainSlider{
    position: relative;
    top: -3vw;
    left: 1vw;
  }
  .checkbox-group input[type="checkbox"]{
    padding: 0;
    -webkit-appearance: none;
    background-color: #00000000;
    border-radius: 50%;
  }
  .checkbox-group input[type="checkbox"]:checked{
    background-color: #b2a2ff;
    color: white;
    border: #000000;
  }
  .checkbox-group #reqText {
        position: absolute;
        height: 6vw;
        width: 31vw;
        font-size: 1vw;
        color: #b2a2ff ;
        padding: 0.5vw 0 0 0.5vw;
        border: 1px solid #5A47B8;
        border-radius: 0.4vw;
        background: #111110;
  }
  .ContactCon .Text input.input-min, .ContactCon .Text input.input-max{
    padding: 0px 0px 0px 0px;
    width: 6.5vw;
    height: 3vw;
    text-align: center;
    margin: 0 0 0 1vw;
    background: none;
    border: none;
    font-size: 1.2vw;
}
  .ContactCon .silder{
    position: relative;
    top: 2vw;
    height: 0.4vw;
    width: 32vw;
    border-radius: 5px;
    background-color: #605790;
  }
  .ContactCon .silder .progress{
    position: absolute;
  /*  left: 25%;
    right: 25%;*/
    height: 0.4vw;
    border-radius: 5px;
    background-color: #b2a2ff;
    -moz-appearance: textfield;
  }
  .ContactCon .price-input{
    position: relative;
    display: flex;
    left: 9vw;
  }
  .ContactCon span{
    font-size: 1.2vw;
  }
  .ContactCon .separator{
    margin: 0vw 2.2vw 0 0vw;
    font-size: 2vw;
  }
  .ContactCon .range-input{
    position: relative;
    top: 2vw;
  }
  .ContactCon .range-input input{
    position: absolute;
    top: -0.25vw;
    left: 0;
    height: 5px;
    width: 32vw;
    padding: 0;
    border:none;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
  }
  .ContactCon .range-input input[type="range"]::-webkit-slider-thumb{
    height: 1.7vw;
    width: 1.7vw;
    border-radius: 50%;
    pointer-events: auto;
    -webkit-appearance: none;
    background: #85CEC1;
  }
  .ContactCon .range-input input[type="range"]::-moz-range-thumb{
    height: 1.7vw;
    width: 1.7vw;
    border-radius: 50%;
    pointer-events: auto;
    -moz-appearance: none;
    background: #85CEC1;
  }
  .ContactCon input.range-min {
    position: absolute;
    z-index: 1;
  }
  .RangeInputMin, .RangeInputMax{
    position: absolute;
    background: #111110;
    color: #b2a2ff;
    margin: 10px 0 0 27px;
  }
  .price-input input::placeholder{
    color: #BDB6E2;
  }
@media (max-width: 1200px) {
        .ContactCon{
          height: 55vw;
        }
        .ContactCon .tittle{
          font-size: 2vw;
        }
        .ContactCon .text1{
          font-size: 2.5vw;
          font-weight: bold;
          margin: -4vw 0vw 0vw 4vw;
          text-align: left;
        }
        .ContactCon .form {
          font-family: 'Courier New', Courier, monospace;
        }
        .ContactCon .custom-form {
          max-width: 100%;
          padding: 0% 0% 3%;
          text-align: left;
          flex-wrap: wrap;
        }

        .ContactCon .Text {
          display: flex;
          justify-content: space-between;
        }

        .ContactCon .Text .form-container{
          display: flex;
          width: 100%;
        }

        .ContactCon .align-left {
          padding: 0% 0% 0% 9%;
          margin-bottom: 5vw;
          width: 43%;
        }
        .ContactCon .align-right {
          padding: 0 0% 0% 2%;
          margin: 1vw 0vw 0vw 10vw;
        }
        .ContactCon .align-right .CheckBox .rightBoxDemand {
          font-size: 2vw;
        }
        .ContactCon .Text label {
          position: relative;
          font-size: 2vw;
          padding: 1vw;
          z-index: 1;
        }
        .ContactCon button{
          padding: 0%;
          left: -14vw;
        }
      }

@media (max-width: 1000px) {
    .ContactCon{
      height: 267vw;
    }
    .ContactCon .tittle{
      padding: 9vw 6vw 2vw 6vw;
    }
    .ContactCon .text1{
      font-size: 7vw;
      margin-top: 2vw;
      margin-left: 2vw;
      text-align: left;
    }
    .ContactCon .custom-form {
        position: relative;
        max-width: 100%;
        text-align: left;
      }
      .ContactCon .align-left {
        margin-top: 5vw;
        margin-left: 17vw;
        width: 30vw;
        z-index: 0;
      }
      .ContactCon .Text .custom-slider{
        position: relative;
        left: 3vw;
        width: 82vw;
        height: 2px;
      }
      .ContactCon .align-right {
        margin-top: -1vw;
        margin-left: 20vw;
        width: 30vw;
      }
      .ContactCon .Text label {
        font-size: 4.5vw;
        padding: 3vw 0vw 1.5vw 0vw;
        margin-left: -22vw;
      }
      .ContactCon .CheckBox label{
        margin-left: -32vw;
      }
      .ContactCon .Text .inputCont {
        width: 90vw;
        height: 12vw;
        font-size: 4vw;
        border-radius: 2vw;
        padding: 0vw 0vw 0vw 3vw;
      }
      .ContactCon .align-left .number .LRNum .rightNum .inputCont {
        width: 57vw;
      }
      .ContactCon .align-left .number .LRNum .leftNum .inputCont {
        width: 32vw;
      }
      .ContactCon .align-right .company {
          z-index: 100;
          left: -43vw;
      }
      .ContactCon #address {
        width: 90vw;
        height: 13vw;
        padding: 0vw 3vw;
        font-size: 4vw;
        border-radius: 2.5vw;
      }
      .custom-form .company{
        position: relative;
        top: 105vw;
        left: -40vw;
      }
      .ContactCon button {
        position: absolute;
        top: 224vw;
        width: 25vw;
        left: 39vw;
        height: 9vw;
        font-size: 3vw;
        padding: 2vw 3vw;
        border-radius: 2vw;
        cursor: pointer;
        color: #000000;
        border: 0.1vw solid #85CEC1;
        background-color: #85CEC1;
      }
      .custom-form .CheckBox{
        position: absolute;
        top: 135vw;
        left: 4vw;
        width: 39vw;
      }
      .ContactCon .align-right .CheckBox .rightBoxDemand{
        position: relative;
        font-size: 4.5vw;
        top: -7vw;
      }
      .custom-form .CheckBox h1{
        font-size: 4.5vw;
      }
      #vehicle1, #vehicle2, #vehicle3, #vehicle4, #vehicle5, #vehicle6 {
        position: relative;
        width: 4vw;
        height: 4vw;
        top: 0vw;
        left: -1vw;
      }
      .CheckBox p {
        font-size: 4.5vw;
      }
  .checkbox-group input[type="checkbox"]{
    padding: 0;
    -webkit-appearance: none;
    background-color: #00000000;
  }
  .checkbox-group input[type="checkbox"]:checked{
    background-color: #b2a2ff;
    color: white;
    border: #000000;
  }
      .checkbox-group #reqText {
        height: 18vw;
        width: 90vw;
        left: -14vw;
        font-size: 4vw;
        padding: 3vw 0 0 3vw;
        top: 12.5vw;
        border-radius: 2vw;
      }
      .CheckBox .checkbox-group label{
        padding: 1vw 1vw 1vw 1vw;
        font-size: 4vw;
      }
      .ContactCon .align-right .CheckBox .checkbox-group span{
        position: relative;
        font-size: 4vw;
      }
      .CheckBox .ContText input{
        position: relative;
        top: 13vw;
        height: 23vw;
      }
      .CheckBox .CheckBox01{
        position: relative;
        top: 2vw;
        left: 26vw;
      }
      .CheckBox .CheckBox02{
        position: relative;
        top: 2vw;
        left: 55vw;
      }
      .CheckBox .CheckBox03{
        position: relative;
        top: 11vw;
        left: 6vw;
      }
      .CheckBox .CheckBox04{
        position: relative;
        top: 3vw;
        left: 65vw;
        width: 42vw;
      }
      .CheckBox .CheckBox05{
        position: relative;
        top: 12vw;
        left: 16vw;
      }
      .CheckBox .CheckBox06{
        position: relative;
        top: 12vw;
        left: 45vw;
      }
      .CheckBox .checkbox-group {
         position: absolute;
         margin: 0 0 0 14vw;
      }
      .Slider {
        position: relative;
        top: 165vw;
        left: -65vw;
        width: 90vw;
      }
      .Slider .tittle {
        font-size: 4.5vw;
        padding: 1vw 6vw 0vw 1vw;
      }
      .Slider .SliderNum {
        top: -7.2vw;
        left: 57.2vw;
        font-size: 4vw;
        width: 18vw;
      }
      .Slider .Price .NT {
        position: relative;
        top: -2vw;
        margin: 0vw;
        left: 46vw;
        font-size: 4vw;
      }
      .Slider .line {
        top: 8vw;
        left: 72vw;
        font-size: 4vw;
      }
      .Slider .p1, .Slider .p2{
        top: 12vw;
        left: 2vw;
        font-size: 3vw;
      }
      .Slider .p2{
        left: 75vw;
      }
      .slider-container {
        top: 14vw;
        left: 4vw;
      }
      .Slider .slider-container input::-webkit-slider-thumb {
        width: 5vw;
        height: 5vw;
        margin-top: -0.8vw;
      }
      .TickMark{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .slider-container .tick-marks1,.slider-container .tick-marks2,
      .slider-container .tick-marks3, .slider-container .tick-marks4,
      .slider-container .tick-marks5, .slider-container .tick-marks6,
      .slider-container .tick-marks7, .slider-container .tick-marks8,
      .slider-container .tick-marks9, .slider-container .tick-marks10,
      .slider-container .tick-marks11, .slider-container .tick-marks12{
        width: 7.45vw;
        left: 2.5vw;
        top: -2.5vw;
      }
      .ContactCon .price-input {
        top: 2vw;
        left: 15vw;
        width: 60vw;
      }
      .ContactCon .silder{
        top: 6vw;
        width: 90vw;
      }
      .ContactCon .range-input{
        top: 5.2vw;
      }
      .ContactCon .range-input input{
        width: 90vw;
      }
      .ContactCon .range-input input[type="range"]::-webkit-slider-thumb{
        height: 3.2vw;
        width: 3.2vw;
      }
      .ContactCon .Text input.input-min, .ContactCon .Text input.input-max{
        width: 15vw;
        height: 8vw;
        font-size: 4vw;
        margin: 7px 0 0 16px;
      }
      .RangeInputMin, .RangeInputMax{
        margin: 14px 0 0 27px;
      }
      .ContactCon .separator{
        margin: 3.5vw 5vw 0 3vw;
      }
      .ContactCon .silder .progress, .ContactCon .silder{
        height: 1vw;
      }
    }
</style>
