<template>
    <div class="hometab tittlecard">
        <video class="background-video" autoplay muted loop playsinline>
            <source src="data/video/Araizen.mp4" type="video/mp4">
        </video>
        <!-- <img src="../assets/logo.png" class="Companylogo" data-tilt data-tilt-glare alt=""> -->
    </div>
</template>

<script>
export default {
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/vanilla-tilt/1.7.2/vanilla-tilt.min.js';
        script.async = true;
        document.body.appendChild(script);
    },
};
</script>

<style>
.hometab {
    padding: 0% 0% 0%;
    position: relative;
}
.tittlecard {
    overflow: hidden;
}

.background-video {
    top: 0; left: 0;
    width: 100%; height: 110%;
}
.tittlecard .Companylogo {
    max-height: 100%;
    max-width: 100%;
}
@media (max-width: 1000px) {
    .hometab {
        padding: 12% 0% 0%;
        position: relative;
    }
    .background-video {
        top: 38px;
    }
}
</style>
