<template>
  <header :class="{ transparent: activeBtn === 0 }">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">
    <router-link to="/" class="brand"
      :class="{ active: activeBtn === 0 }"
      @click="sliderNav(0)">
      <img width="10754" height="4867" src="./assets/data/BackGround/logo.png" alt="Logo" />
    </router-link>

    <nav :class="{ 'navigation active': isMenuActive, 'navigation': !isMenuActive }">
      <div class="navigation-items">
<div class="Tittle">
          <router-link to="/" id="homeBtn" class="nav-btn"
                  :class="{ active: activeBtn === 1 }"
                  @click="sliderNav(1)">
                  {{ $t('ff.Home') }}
          </router-link>
        <router-link to="/project" id="project" class="nav-btn"
              :class="{ active: activeBtn === 2 }"
              @click="sliderNav(2)">
              {{$t('ff.Project')}}
        </router-link>
        <router-link to="/about" id="about" class="nav-btn"
              :class="{ active: activeBtn === 3 }"
              @click="sliderNav(3)">
              {{$t('ff.About')}}
        </router-link>
        <router-link to="/contact" id="contact" class="nav-btn"
              :class="{ active: activeBtn === 4 }"
              @click="sliderNav(4)">
              {{$t('ff.Contact')}}
        </router-link>
        <router-link to="" id="news" class="nav-btn"
                :class="{ active: activeBtn === 5 }"
                @click="sliderNav(5)">
                {{ $t('ff.News') }}
        </router-link>
        <a class="langBtn">
          <button @click="switchCh()" class="pointerHover" >
            {{ $t('ff.LanguageCH') }}
          </button>

          <span class="separator">|</span>

          <button @click="switchEn()" class="pointerHover">
            {{ $t('ff.LanguageEng') }}
          </button>
        </a>
  </div>
      </div>
    </nav>

    <div class="icons">
      <div
        id="menu-btn"
        :class="{ 'fas fa-bars': !isMenuActive, 'fas fa-times': isMenuActive
      , show: isNavigationVisible}"
        @click="toggleMenu"
        @keydown="handleKeyboardEvent"
        tabindex="0"
        ref="menuBtn"
      ></div>
    </div>

  </header>
  <div>
    <button @click="scrollToTop" class="scroll-button" v-show="showScrollButton">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
  <router-view/>
  <footerTab></footerTab>

</template>

<script>
  import { watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  import footerTab from '@/components/FooterSection.vue';

  export default {
    components: {
      footerTab,
    },
  data() {
    return {
      isMenuActive: false,
      activeBtn: 0,
      optionsLang: [
        { text: '中文', value: 'ch' },
        { text: 'English', value: 'en' },
      ],
      showScrollButton: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    sliderNav(manual) {
        this.activeBtn = manual;
        if (manual !== 0) {
          this.isMenuActive = !this.isMenuActive;
        }
    },
    switchLanguage(locale) {
      const newLocale = this.$i18n.locale === 'ch' ? 'en' : 'ch';
      this.$i18n.locale = newLocale;
      this.currentLanguage = newLocale;
    },
    switchEn() {
      console.log('en');
      this.$i18n.locale = 'en';
    },
    switchCh() {
      console.log('ch');
      this.$i18n.locale = 'ch';
    },
    setLang(value) {
      console.log('setLang');
      this.$store.commit('setLang', value);
      this.$i18n.locale = value;
      localStorage.setItem('footmark-lang', value);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    handleScroll() {
      if (window.pageYOffset > 300) {
        this.showScrollButton = true;
      } else {
        this.showScrollButton = false;
      }
    },
  },
  setup() {
    const { t, locale } = useI18n();

    watch(locale, (newlocale) => {
      localStorage.setItem('locale', newlocale);
    });

    return {
      t,
      locale,
    };
  },
};
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  cursor:url('https://www.flaticon.com/free-icon/new-moon_686700?term=circle&related_id=686700');
  overflow-x: hidden;
  background-image: linear-gradient(to top right, #170F1A, #1E1848);
  background-image: linear-gradient(to top left, #170F1A, #1E1846);
  background-image: linear-gradient(to bottom right, #170F1A, #1E1848);
  background-image: linear-gradient(to bottom left, #170F1A, #29215e);
}

header{
  position: fixed;
  z-index: 999;
  top: -6px; left: 0;
  width: 100vw;
  height: 5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1em; font-weight: bold;
  padding: 0% 1%;
  transition: 0.5s ease;
  background-color:#362B6F;
}

header.transparent {
  background-color:#362B6F;
}

.brand{
  margin-left: -3.3vw;
}

.brand img{
  width: 26.5vw;
  height: 11vw;
}

header .icons {
  display: none;
}

header .navigation{
  position: relative;
}

.langBtn .separator {
  margin: 0 0.5vw;
  color: #ffffff;
}

header .navigation .navigation-items a{
  position: relative;
  font-size: 1.25vw;
  font-weight: 600;
  text-decoration: none;
  margin-left: 1.95vw;
  margin-right: 1.95vw;
  padding: 0.2vw;
}

header .navigation .navigation-items #Home{
  display: none;
}

header .navigation .navigation-items button{
  position: relative;
  font-size: 1.2vw; font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  border: #ffffff00;
  background-color: #a2a2a200;
}

header .navigation .navigation-items .langBtn{
  position: relative;
  margin-right: 0vw
}

header .navigation .pointerHover{
  cursor: pointer;
}

header .navigation .nav-btn.active {
  color: #838383;
}
.navigation-items .Tittle{
  display: flex;
  padding: 0%;
}
.navigation-items .Tittle #news{
  color: #2b1c52;
  cursor: default;
}
@media (min-height: 20vw) {

  .scroll-button{
    position: fixed;
    top: 41vw; right: 2vw;
    width: 2.8vw; height: 2.8vw;
    color:#85CEC1;
    font-size: 1.35vw;
    border-radius: 50%;
    border: 0.15vw solid #85CEC1;
    background-color: rgba(0, 0, 0, 0);
    z-index: 100;
  }

  .scroll-button:hover{
    cursor: pointer;
    color: rgb(0, 0, 0);
    border: rgba(0, 0, 0, 0);
    background-color: #85CEC1;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #ffffff;

    &.router-link-exact-active {
      color: #ffffff;
    }
  }
}

@media (max-width: 1000px) {
    body {
      cursor:url('https://www.flaticon.com/free-icon/new-moon_686700?term=circle&related_id=686700');
      overflow-x: hidden; /* 隱藏水平滾動條 */
      background-color: #18122C;
    }
    header{
      height: 14vw;
    }
    header .icons {
      position: fixed;
      display: inline;
      right: 5vw;
      font-size: 5.5vw;
    }
    .brand img{
      width: 60vw;
      height: 26vw;
      margin-left: -4vw;
    }
    header #menu-btn {
        display: inline-block;
    }
    header #menu-btn.fa-times {
        transform: rotate(180deg);
    }/*
    header .navigation{
      position: absolute;
      display: none;
    }
    header .navigation.active{
      display: inline;
    }*/
    header .navigation.active .navigation-items{
      position: fixed;
      top: 0px;
      left: 0vw;
      width: 100vw;
      height: 100vh;
      background-color: #1F183F;
      animation: show 1s;
    }
    header .navigation .navigation-items{
      position: fixed;
      top: 0px;
      left: 100vw;
      width: 100vw;
      height: 100vh;
      background-color: #1F183F;
      animation: hide 1s;
    }
    @keyframes show {
      0%{
        opacity: 100;
        transform: translate3d(100vw, 0, 0);
      }
    }
    @keyframes hide {
      0%{
        opacity: 100;
        transform: translate3d(-100vw, 0, 0);
      }
    }
    header .navigation .navigation-items a{
      font-size: 4.5vw ;
      padding: 10vw 0vw 5vw 3vw;
      text-align: left;
      width: 85vw;
      border-bottom: 1px solid #9C91D4;
    }
    header .navigation .navigation-items button{
      font-size: 4.5vw ;
    }
    header .navigation .navigation-items .langBtn{
      border-bottom: 0px;
      left: 32vw;
      top: 10vw;
    }
  .scroll-button {
      top: 254vw;
      left: 90vw;
      width: 6.8vw;
      height: 6.8vw;
  }
  .navigation-items .Tittle{
    position: relative;
    display: inline-grid;
    padding: 0%;
    top: 10vw;
  }
}
/* 滾動條軌道 */
::-webkit-scrollbar-track {
  background-color: #362B6F;
}
::-webkit-scrollbar {
  width: 0.5vw;
  background-color: #f1f1f1;
}
/* 滾動條滑塊 */
::-webkit-scrollbar-thumb {
  background-color: #604fb4e2;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #604fb49e;
}
</style>
