<template>
    <div class="partner-tittle">
        <h1 class="AboutPartner">{{ $t('ff.AboutPartner') }}</h1>

        <div class="partner-cont">
            <div class="partner-Backg">
                <div class="partner-photo"
                    v-for="(classification) in classifications"
                    :key="classification.id"
                    :style="{
                        'background-image': `url(${classification.img})`,
                        'background-size': `cover`,
                        'background-position': `center`
                    }">
                </div>
            </div>
        </div>
        <button class="count" @click="redirectToAnotherPage">{{$t('ff.AboutButton')}}</button>
    </div>
</template>

<script>
import router from '@/router';

export default {
    data() {
        return {
            classifications: [],
        };
    },
    mounted() {
        fetch('./json/coopPartner.json')
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Failed to fetch data.json');
            })
            .then((data) => {
                this.classifications = data;
                console.log(this.classifications);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    },
    methods: {
        redirectToAnotherPage() {
            router.push('./contact');
        },
    },

};
</script>

<style>
.partner-tittle {
    padding: 3% 0% 0%;
    color: white;
    text-align: left;
}
.partner-tittle .AboutPartner {
    font-size: 3vw;
    padding: 0% 5% 3.5%;
}

.partner-cont {
  position: relative;
  padding: 0% 3% 1.5%;
}

.partner-Backg {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.partner-photo {
  flex-basis: calc(100% / 6 - 2vw);
  transform: scale(0.8);
  width: 10vw;
  height: 9vw;
  margin: 1vw 0vw;
}
.partner-tittle .count{
    margin-left: 45vw;
    margin-bottom: 5vw;
    padding: 0.8vw 5vw;
    font-size: 1.15vw;
    background-color: #85CEC1;
    color: #000000;
    border: 0.1vw solid #85CEC1;
    border-radius: 0.4vw;
    cursor: pointer;
}
.partner-tittle .count:hover {
    color: #ffffff;
    background-color: #00000000;
  }
  @media (max-width: 1000px) {
    .partner-tittle .count{
        width: 32vw;
        font-size: 3vw;
        margin: 5vw 0vw 9vw 33vw;
        padding: 3vw 6vw;
        border-radius: 2vw;
    }
    .partner-photo {
        flex-basis: calc(100% / 3 - 2vw);
        height: 20vw;
    }
    .tittle h1 {
        font-size: 6vw;
        padding: 0% 0% 3.5%;
    }
    .partner-tittle .AboutPartner {
        font-size: 6vw;
        padding: 0% 5% 3.5%;
    }
  }
</style>
